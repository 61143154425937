import React, { useEffect } from 'react';
import { connectRange } from 'react-instantsearch-dom';
import { RangeSlider } from '@homeproved/shared/ui';

const RangeSliderAlgolia = ({
  min,
  max,
  currentRefinement,
  refine,
  onNoDefaultRefinement,
  touchedRadios,
}) => {
  const onChange = (newValue: number[]) => {
    refine({ min: newValue[0], max: newValue[1] });
  };

  useEffect(() => {
    if (currentRefinement?.min == null && currentRefinement?.max == null && !touchedRadios) {
      onNoDefaultRefinement();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (min == null || max == null) {
    return null;
  }

  return (
    <RangeSlider
      minValue={min}
      maxValue={max}
      min={currentRefinement.min}
      max={currentRefinement.max}
      onChange={onChange}
    />
  );
};

export const CustomRangeSlider = connectRange(RangeSliderAlgolia);
