import React, { FC, useEffect } from 'react';
import { useMediaQuery, useTheme } from '@material-ui/core';
import styled from 'styled-components';
import { InstantSearchProps } from 'react-instantsearch-dom';
import { Header, Intro, IntroText, Title } from './components/Atoms';
import { ReviewSection } from './components/ReviewSection';
import { ArticleSection } from './components/ArticleSection';
import { SearchSection } from '@homeproved/shared/feature-company-search';
import { SectorData } from '@homeproved/shared/data-access';
import { GetPathFunction } from '@homeproved/shared/feature-localized-routes';
import { Breadcrumb } from '@homeproved/shared/ui';
import { Language, useCurrentLanguage, useSlugs } from '@homeproved/shared/feature-i18n';
import { useRouter } from 'next/router';

const PageWrapper = styled(({ mobile, ...restProps }) => <div {...restProps} />)``;

const Inner = styled.div`
  padding: 0 2rem;
  margin: auto;
`;

export type SectorPageProps = {
  sector: SectorData;
  getPath: GetPathFunction;
  searchClient: InstantSearchProps['searchClient'];
  indexName: InstantSearchProps['indexName'];
  resultsState: InstantSearchProps['resultsState'];
};

export const SectorPage: FC<SectorPageProps> = ({
  sector,
  getPath,
  searchClient,
  indexName,
  resultsState,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.sm));
  const isTablet = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.md));
  const isDesktop = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.lg));

  const currentLanguage = useCurrentLanguage();
  const { setSlugs } = useSlugs();
  const router = useRouter();
  const { page } = router.query;
  const { query } = router.query;

  useEffect(() => {
    //Set slugs for language switcher
    setSlugs({
      sector: sector.slug.data as Record<Language, string>,
    });
  }, [sector, setSlugs]);

  return (
    <PageWrapper>
      {!isMobile && (
        <Breadcrumb includeHomepageLink>
          <strong>{sector.name}</strong>
        </Breadcrumb>
      )}
      <div>
        <Header
          mobile={isMobile}
          bgImage={
            sector.banner &&
            ((isMobile || isTablet)
              ? sector.banner?.data?.conversions?.['small'] || sector.banner?.data?.original
              : isDesktop ? sector.banner?.data?.original : sector.banner?.data?.conversions?.['small']
            )
          }
        >
          {!isMobile && (
            <Inner>
              <Intro mobile={isMobile}>
                <Title variant="h1" mobile={isMobile}>
                  {sector.name}
                </Title>
                <IntroText variant="body1">{sector.description}</IntroText>
              </Intro>
            </Inner>
          )}
        </Header>
        {isMobile && (
          <Intro mobile={isMobile}>
            <Title variant="h1" mobile={false}>
              {sector.name}
            </Title>
            <IntroText variant="body1">{sector.description}</IntroText>
          </Intro>
        )}
      </div>
      <SearchSection
        isMobile={isMobile}
        getPath={getPath}
        searchClient={searchClient}
        indexName={indexName}
        resultsState={resultsState}
        sectorName={sector.name}
        page={page?.toString()}
        query={query?.toString()}
      />
      <ReviewSection
        isMobile={isMobile}
        sectorName={sector.name}
        sectorSlug={sector.slug.data[currentLanguage]}
        getPath={getPath}
      />
      <ArticleSection
        isMobile={isMobile}
        sectorName={sector.name}
        getPath={getPath}
        sectorSlug={sector.slug.data[currentLanguage]}
      />
    </PageWrapper>
  );
};
