import React, { FC, useEffect } from 'react';
import { useMediaQuery, useTheme } from '@material-ui/core';
import styled from 'styled-components';
import { InstantSearchProps } from 'react-instantsearch-dom';
import { Header, Intro, IntroText, Title } from './components/Atoms';
import { ReviewSection } from './components/ReviewSection';
import { ArticleSection } from './components/ArticleSection';
import { SearchSection } from '@homeproved/shared/feature-company-search';
import { SectorData } from '@homeproved/shared/data-access';
import { GetPathFunction } from '@homeproved/shared/feature-localized-routes';
import { Breadcrumb } from '@homeproved/shared/ui';
import Link from 'next/link';
import { StyledA } from './sector-page/Atoms';
import { Language, useCurrentLanguage, useSlugs } from '@homeproved/shared/feature-i18n';
import { useRouter } from 'next/router';

const PageWrapper = styled(({ mobile, ...restProps }) => <div {...restProps} />)``;

const Inner = styled.div`
  padding: 0 2rem;
  margin: auto;
`;

export type SubsectorPageProps = {
  subSector: SectorData;
  sector: SectorData;
  getPath: GetPathFunction;
  searchClient: InstantSearchProps['searchClient'];
  indexName: InstantSearchProps['indexName'];
  resultsState: InstantSearchProps['resultsState'];
};

export const SubSectorPage: FC<SubsectorPageProps> = ({
  sector,
  subSector,
  getPath,
  searchClient,
  indexName,
  resultsState,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.sm));
  const isTablet = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.md));
  const isDesktop = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.lg));

  const currentLanguage = useCurrentLanguage();
  const { setSlugs } = useSlugs();
  const router = useRouter();
  const { page } = router.query;
  const { query } = router.query;

  useEffect(() => {
    //Set slugs for language switcher
    setSlugs({
      sector: sector.slug.data as Record<Language, string>,
      subsector: subSector.slug.data as Record<Language, string>,
    });
  }, [sector, subSector, setSlugs]);

  return (
    <PageWrapper>
      {!isMobile && (
        <Breadcrumb includeHomepageLink>
          <Link
            href={getPath('/sectors/:sector', {
              sector: sector.slug.data[currentLanguage],
            })}
            passHref
          >
            <StyledA href={getPath('/sectors')}>{sector.name}</StyledA>
          </Link>
          {' / '}
          <strong>{subSector.name}</strong>
        </Breadcrumb>
      )}
      <div>
        <Header
          mobile={isMobile}
          bgImage={
            subSector.banner &&
            ((isMobile || isTablet)
                ? subSector.banner?.data?.conversions?.['small'] || subSector.banner?.data?.original
                : isDesktop ? subSector.banner?.data?.original : subSector.banner?.data?.conversions?.['small']
            )
          }
        >
          {!isMobile && (
            <Inner>
              <Intro mobile={isMobile}>
                <Title variant="h1" mobile={isMobile}>
                  {subSector.name}
                </Title>
                <IntroText variant="body1">{subSector.description}</IntroText>
              </Intro>
            </Inner>
          )}
        </Header>
        {isMobile && (
          <Intro mobile={isMobile}>
            <Title variant="h1" mobile={false}>
              {subSector.name}
            </Title>
            <IntroText variant="body1">{subSector.description}</IntroText>
          </Intro>
        )}
      </div>
      <SearchSection
        isMobile={isMobile}
        getPath={getPath}
        searchClient={searchClient}
        indexName={indexName}
        resultsState={resultsState}
        sectorName={sector.name}
        subSectorName={subSector.name}
        page={page?.toString()}
        query={query?.toString()}
      />
      <ReviewSection
        isMobile={isMobile}
        sectorName={subSector.name}
        sectorSlug={subSector.slug.data[currentLanguage]}
        getPath={getPath}
      />
      <ArticleSection
        isMobile={isMobile}
        sectorName={subSector.name}
        getPath={getPath}
        sectorSlug={subSector.slug.data[currentLanguage]}
      />
    </PageWrapper>
  );
};
