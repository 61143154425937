import React, { FC } from 'react';
import { connectHits } from 'react-instantsearch-dom';
import { CompanySearchCard } from './CompanySearchCard';
import { HitsProvided } from 'react-instantsearch-core';
import { CompanyData } from '@homeproved/shared/data-access';
import { GetPathFunction } from '@homeproved/shared/feature-localized-routes';
import { Box, useMediaQuery, useTheme } from '@material-ui/core';
import { Button } from '@homeproved/shared/ui';
import { useTranslation } from 'react-i18next';

export type ExtraCompanyData = {
  score?: {
    data?: {
      total?: number;
      score?: number;
      google_score?: number;
      google_total?: number;
    };
  };
  media?: [
    {
      data?: {
        conversions: {
          fit: string;
          square: string;
        };
        fileName: string;
        id: number;
        original: string;
      };
    },
    {
      data?: {
        fileName: string;
        id: number;
        original: string;
      };
    }
  ];
  claimed_at?: number;
  google_reviews?: boolean;
};

type CustomHitsProps = HitsProvided<CompanyData & ExtraCompanyData> & {
  getPath: GetPathFunction;
  review?: boolean;
};

const Hits: FC<CustomHitsProps> = ({ hits, getPath, review = false }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.sm));
  return hits.length > 0 ? (
    <>
      {hits.map((hit) => (
        <CompanySearchCard company={hit} key={hit.objectID} getPath={getPath} review={review} />
      ))}
    </>
  ) : (
    <Box mt={isTablet ? 3 : 2} display="flex" justifyContent={isTablet ? 'center' : 'flex-start'}>
      <Button variant="gradient" href={getPath('/add-company')}>
        {t('app.com.pages.companySearch.mainSection.addCompanyCta')}
      </Button>
    </Box>
  );
};

export const CustomHits = connectHits<CustomHitsProps, CompanyData>(Hits);
