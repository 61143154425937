import React, { FC } from 'react';
import { Button, Icons, LogoCom } from '@homeproved/shared/ui';
import styled from 'styled-components';
import { LanguageSwitcher } from '@homeproved/shared/feature-language-switcher';
import { useTranslation } from 'react-i18next';
import { useLocalizedRoutes } from '@homeproved/com/feature-localized-routes';
import Link from 'next/link';

type DesktopHeaderProps = {
  homepage?: boolean;
  transparent?: boolean;
};

const Wrapper = styled(({ ...restProps }) => <div {...restProps} />)`
  background: ${({ theme }) => theme.config.gradients.default};
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 2rem;
  position: relative;
  z-index: 99;
`;

const Inner = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 10rem;
  max-width:  144rem;
  width: 100%;
  padding: 0 2rem;
  margin: auto;
`;

const Left = styled.div`
  display: flex;
  align-items: center;
`;

const Right = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  button:last-child {
    margin-left: 10px;
  }
`;

const StyledLanguageSwitcher = styled(LanguageSwitcher)`
  margin-left: 2rem;
`;

const StyledLogoCom = styled(({ homepage, ...restProps }) => <LogoCom {...restProps} />)`
  padding-bottom: ${({ homepage }) => (homepage ? '1rem' : '1.2rem')};
  &:hover {
    cursor: pointer;
  }
`;

export const DesktopHeader: FC<DesktopHeaderProps> = ({
  homepage = false,
  transparent = false,
}) => {
  const { t } = useTranslation();
  const { getPath } = useLocalizedRoutes();

  return (
    <Wrapper transparent={transparent}>
      <Inner>
        <Left>
          <Link href={getPath('/')} passHref>
            <a href={getPath('/')}>
              <StyledLogoCom homepage={homepage} width={homepage ? '35rem' : '26.5rem'} />
            </a>
          </Link>
          <StyledLanguageSwitcher getPath={getPath} />
        </Left>
        <Right>
          <Button
            variant="ghost"
            arrow="none"
            size="medium"
            href={getPath('/company-search')}
            icon={Icons.SEARCH}
          >
            {t('app.com.header.workers.menu.findWorker')}
          </Button>
          <Button
            variant="ghost"
            arrow="none"
            size="medium"
            href={getPath('/write-review')}
            icon={Icons.PENCIL}
          >
            {t('app.com.header.reviews.menu.writeReviews')}
          </Button>
          <Button
            variant="ghost"
            arrow="none"
            size="medium"
            href={getPath('/sectors')}
            icon={Icons.BRICK_WALL}
          >
            {t('app.com.header.workers.menu.sectors')}
          </Button>
          <Button
            variant="ghost"
            arrow="none"
            size="medium"
            href={getPath('/housing-advice')}
            icon={Icons.HOUSE_HEART}
          >
            {t('app.com.header.inspiration.menu.advice')}
          </Button>
          <Button variant="white" href="https://join.homeproved.pro/" target="_blank">
            <span>{t('app.com.header.forCompanies')}</span>
          </Button>
        </Right>
      </Inner>
    </Wrapper>
  );
};
