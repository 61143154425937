import {
  RefinementListProvided,
  RefinementListExposed,
  connectRefinementList,
} from 'react-instantsearch-core';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Checkbox } from '@homeproved/shared/feature-forms';
import { FilteredList, FilteredListItem, StyledButton } from './Atoms';
import { Sector, SectorDescendant } from '@homeproved/shared/data-access';

type Props = RefinementListProvided & { mobile: boolean; sectors: Sector[]; showMore: boolean };

const SectorsMenuComponent = ({ items, refine, mobile, sectors, showMore }: Props) => {
  const [sectorList, setSectorList] = useState([]);
  const [initialLoaded, setInitialLoaded] = useState(false);

  const refinedItems = useMemo(
    () => items.filter((item) => item.isRefined === true).map((item) => item.label),
    [items]
  );

  const shouldBeChecked = useCallback(
    (sector) => {
      return sector.data?.descendants.some((descendant: SectorDescendant) =>
        refinedItems.includes(descendant.data.id.toString())
      );
    },
    [refinedItems]
  );

  useEffect(() => {
    if (!initialLoaded) {
      setSectorList(
        sectors.map((sector) => ({ ...sector.data, checked: shouldBeChecked(sector) }))
      );
    }
  }, [sectors, refinedItems, initialLoaded, shouldBeChecked]);

  const onSectorChecked = (id) => {
    setInitialLoaded(true);
    setSectorList(
      sectorList.map((sector) =>
        sector.id === id ? { ...sector, checked: !sector.checked } : sector
      )
    );
    const descendantIds = sectorList
      .find((sector) => sector.id === id)
      .descendants.map((descendant: SectorDescendant) => descendant.data.id.toString());
    refine(refinedItems.filter((refinedItem) => !descendantIds.includes(refinedItem)));
  };

  return (
    <FilteredList>
      {sectorList.slice(0, showMore ? 999 : 10).map((sector) => (
        <FilteredListItem key={`sector-${sector.id}`}>
          <StyledButton
            sectorChecked={sector.checked}
            variant="text"
            arrow={'right'}
            showArrowForVariantText
            onClick={() => onSectorChecked(sector.id)}
          >
            {sector.name}
          </StyledButton>
          {sector.checked && (
            <FilteredList>
              {sector.descendants.map((subSector) => {
                const isRefined = refinedItems.includes(subSector['data'].id.toString());
                return (
                  <FilteredListItem key={`subsector-${subSector['data'].id}`}>
                    <Checkbox
                      label={subSector['data'].name}
                      labelSize={1.5}
                      value={isRefined}
                      onChange={() =>
                        isRefined
                          ? refine(
                              refinedItems.filter(
                                (refinedItem) => refinedItem !== subSector['data'].id.toString()
                              )
                            )
                          : refine([...refinedItems, subSector['data'].id])
                      }
                      height={mobile ? 3.5 : 3}
                      labelWeight={isRefined && 700}
                      labelCapitalized
                    />
                  </FilteredListItem>
                );
              })}
            </FilteredList>
          )}
        </FilteredListItem>
      ))}
    </FilteredList>
  );
};

export const SectorsMenu = connectRefinementList(SectorsMenuComponent) as React.ComponentClass<
  RefinementListExposed & { mobile: boolean; sectors: Sector[]; showMore: boolean }
>;
