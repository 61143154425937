import React, { FC } from 'react';
import { DesktopHeader } from './DesktopHeader';
import { MobileHeader } from './MobileHeader';
import { useMediaQuery, useTheme } from '@material-ui/core';

type HeaderProps = {
  homepage?: boolean;
  transparent?: boolean;
  gradient?: boolean;
};

export const Header: FC<HeaderProps> = ({
  homepage = false,
  transparent = false,
  gradient = false,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.md));

  return isMobile ? (
    <MobileHeader gradient={gradient} transparent={transparent} />
  ) : (
    <DesktopHeader homepage={homepage} transparent={transparent} />
  );
};
