import {
  RefinementListProvided,
  RefinementListExposed,
  connectRefinementList,
} from 'react-instantsearch-core';
import React from 'react';
import { Checkbox } from '@homeproved/shared/feature-forms';
import { useTranslation } from 'react-i18next';
import { FilteredList, FilteredListItem } from './Atoms';

type Props = RefinementListProvided & { mobile: boolean; onSelect: (list: string[]) => void };

const CountryMenuComponent = ({ currentRefinement, refine, mobile, onSelect }: Props) => {
  const { t } = useTranslation();
  const refinedItems = currentRefinement;
  const countries = ['be', 'nl'];

  const handleChange = (isRefined, country) => {
    const newList = isRefined
      ? refinedItems.filter((refinedItem) => refinedItem !== country)
      : [...new Set([...refinedItems, country])]

    refine(newList);
    onSelect(newList);
  };

  return (
    <FilteredList>
      {countries.map((country) => {
        const isRefined = refinedItems.includes(country);
        return (
          <FilteredListItem key={country}>
            <Checkbox
              label={t(`countries.${country}`)}
              labelSize={1.5}
              value={isRefined}
              onChange={() => handleChange(isRefined, country)}
              height={mobile ? 3.5 : 3}
              labelWeight={isRefined && 700}
              labelCapitalized
            />
          </FilteredListItem>
        );
      })}
    </FilteredList>
  );
};

export const CountryMenu = connectRefinementList(CountryMenuComponent) as React.ComponentClass<
  RefinementListExposed & { mobile: boolean; onSelect: (list: string[]) => void }
>;
