import React, { FC, useState } from 'react';
import { Configure, Index, InstantSearch, InstantSearchProps } from 'react-instantsearch-dom';
import { CustomHits, CustomStats, useCoordinates } from '@homeproved/shared/feature-company-search';
import { useCurrentLanguage } from '@homeproved/shared/feature-i18n';
import { GetPathFunction } from '@homeproved/shared/feature-localized-routes';
import { Box } from '@material-ui/core';
import { Button } from '@homeproved/shared/ui';
import { useTranslation } from 'react-i18next';

type Props = {
  sector: string;
  getPath: GetPathFunction;
  searchClient?: InstantSearchProps['searchClient'];
  indexName?: InstantSearchProps['indexName'];
  resultsState?: InstantSearchProps['resultsState'];
  widgetsCollector?: InstantSearchProps['widgetsCollector'];
  setTotalHits?: React.Dispatch<React.SetStateAction<number>>;
  onExecuteScroll?: () => void;
  location?: {
    lat: number;
    lng: number;
  };
  totalHits?: number;
  isMobile?: boolean;
};
export const CompanyList: FC<Props> = ({
  searchClient,
  indexName,
  resultsState,
  widgetsCollector,
  sector,
  getPath,
  setTotalHits,
  onExecuteScroll,
  location,
  totalHits,
  isMobile,
}) => {
  const currentLanguage = useCurrentLanguage();

  const { geoProps } = useCoordinates(20000, location);

  const [hitsPerPage, setHitsPerPage] = useState(5);
  const { t } = useTranslation();

  return (
    <InstantSearch
      searchClient={searchClient}
      indexName={indexName}
      resultsState={resultsState}
      widgetsCollector={widgetsCollector}
    >
      <CustomStats setTotalHits={setTotalHits} />
      <Index indexName={indexName}>
        <Configure
          facetFilters={`${currentLanguage}.sectors.lvl0:${sector}`}
          hitsPerPage={hitsPerPage}
          // restrictSearchableAttributes="city"
          // query={locality}
          {...geoProps}
        />
      </Index>

      <CustomHits getPath={getPath} />
      {(totalHits || 0) > hitsPerPage && (
        <Box display="flex" flexDirection="column" alignItems="center" pt={2} pb={5}>
          <Button variant="light" onClick={() => setHitsPerPage(hitsPerPage + 5)}>
            {t('app.com.pages.sectors.subSector.moreResults')}
          </Button>
        </Box>
      )}
    </InstantSearch>
  );
};
