import { Button, Chip } from '@homeproved/shared/ui';
import { Box, Typography } from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';

/*
 * START Styles for CompanySearchCard
 */
export const Tags = styled(({ mobile, ...restProps }) => <div {...restProps} />)`
  font-weight: 300;
  font-size: 1.4rem;
  font-family: ${({ theme }) => theme.config.fonts.PTSans};
  color: #888;
  width: ${({ mobile }) => (mobile ? '250px' : '400px')};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-left: ${({ mobile }) => (mobile ? '.25rem' : '.5rem')};
`;

export const Company = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  width: 100%;
  padding: 1rem;
`;

export const StarsContainer = styled.div`
  display: flex;
  flex-wrap: nowrap;
  margin-right: 2rem;
  min-width: 150px;
`;

export const RatingWrapper = styled(Box)`
  padding-bottom: 2rem;
  border-bottom: 0.1rem solid ${({ theme }) => theme.palette.grey['300']};
  margin-bottom: 2rem;
`;

export const RatingExtra = styled.div`
  margin-left: 2rem;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: space-between;
`;
export const Reviews = styled.span`
  font-size: 1.4rem;
  min-width: 200px;

  span {
    margin-right: 5px;

    &.bold {
      font-weight: bold;
    }
  }
`;
export const Title = styled(Typography)`
  font-size: 1.8rem;
  font-weight: 600;
  font-family: ${({ theme }) => theme.config.fonts.PTSans};
  text-transform: uppercase;
  margin-bottom: 2rem;
  margin-right: 1rem;
  max-width: 350px;
`;
export const SearchSectionTitle = styled(({ mobile, ...restProps }) => (
  <Typography {...restProps} />
))`
  font-size: ${({ mobile }) => (mobile ? '1.8rem' : '2.4rem')};
  margin-bottom: 1rem;
  text-align: ${({ mobile }) => (mobile ? 'center' : 'left')};
`;

export const Logo = styled(({ image, ...restProps }) => <div {...restProps} />)`
  width: 7.6rem;
  height: 7.6rem;
  padding: 0.8rem;
  border-radius: ${({ theme }) => theme.config.defaultBorderRadius};
  border: 0.1rem solid ${({ theme }) => theme.palette.grey['300']};
  position: absolute;
  right: 0;
  z-index: 9;
  overflow: hidden;

  &:after {
    content: '';
    display: block;
    padding-bottom: 100%;
    background: ${({ image }) => (image != null ? `url(${image}) center no-repeat` : '')};
    background-size: contain;
  }
`;

export const LocationTag = styled(({ mobile, ...restProps }) => <div {...restProps} />)`
  font-size: 1.4rem;
  font-weight: ${({ mobile }) => (mobile ? 300 : 600)};
  text-transform: capitalize;
  font-family: ${({ theme }) => theme.config.fonts.PTSans};
  margin-left: ${({ mobile }) => (mobile ? '.75rem' : '.5rem')};
`;

export const StyledChip = styled(Chip)`
  margin: 0.5rem;
`;

export const FlagValidated = styled.div`
  position: absolute;
  right: -4.5rem;
  top: -4.5rem;
  width: 9rem;
  height: 9rem;
  z-index: 10;
`;

export const IconOuter = styled.div`
  border: 1px solid ${({ theme }) => theme.palette.primary.main};
  border-radius: 50%;
  position: absolute;
  right: 5rem;
  top: 5rem;
  width: 2.2rem;
  height: 2.2rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const TextButton = styled(Button)`
  color: #000;
  font-weight: 600;
  font-size: 1.2rem;
  font-family: pt-sans, Arial, sans-serif;
  align-self: center;
  cursor: pointer;
  text-decoration: underline;
`;

/*
 * END Styles for CompanySearchCard
 */

/*
 * START Styles for SearchSection
 */

export const SearchLabel = styled(({ mobile, ...restProps }) => <Typography {...restProps} />)`
  font-size: 1.6rem;
  font-family: ${({ theme }) => theme.config.fonts.PTSans};
  margin-bottom: ${({ mobile }) => (mobile ? '1.5rem' : '0.7rem')};
  text-align: ${({ mobile }) => (mobile ? 'center' : 'left')};
`;

export const SearchTitle = styled(Typography)`
  font-size: 1.8rem;
  margin-bottom: 2rem;
`;

export const ContentWrapper = styled(({ mobile, innerRef, ...restProps }) => (
  <div ref={innerRef} {...restProps} />
))`
  max-width: 115.6rem;
  padding-top: ${({ mobile }) => (mobile ? '2rem' : '4rem')};
  padding-left: 2rem;
  padding-right: 2rem;
  margin: auto;
`;

export const SearchWrapper = styled(({ mobile, ...restProps }) => <div {...restProps} />)`
  max-width: 50rem;
  width: ${({ mobile }) => (mobile ? '100%' : '40rem')};
  margin: ${({ mobile }) => (mobile ? 'auto' : 0)};
  input {
    border: 1px solid;
  }
`;

export const StyledLink = styled.a<{ mobile: boolean }>`
  text-align: ${({ mobile }) => (mobile ? 'center' : 'left')};
  text-decoration: underline;
  cursor: pointer;
  color: inherit;
`;

/*
 * END Styles for SearchSection
 */
