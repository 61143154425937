import {
  CustomRangeSlider,
  CustomRatingMenu,
  ProvincesMenu,
  CountryMenu,
  SectorsMenu,
} from '@homeproved/com/feature-search';
import { Radio, StarRating } from '@homeproved/shared/feature-forms';
import { Button } from '@homeproved/shared/ui';
import { RadioGroup } from '@material-ui/core';
import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import FilterSection from './FilterSection';
import { useSectors } from '@homeproved/shared/feature-sectors';

type FilterComponentProps = {
  isMobile: boolean;
};

const StyledTextButton = styled(Button)`
  font-size: 1.4rem;
  font-weight: 700;
  text-transform: uppercase;
  font-family: ${({ theme }) => theme.config.fonts.PTSans};
  margin-top: 1rem;
`;

const FilterComponent: FC<FilterComponentProps> = ({ isMobile }) => {
  const { t } = useTranslation();

  const [reviewsValue, setReviewsValue] = useState(
    t('app.com.pages.companySearch.filterSection.withReviews').toString()
  );
  const [showMore, setShowMore] = useState(false);
  const [showAllScores, setShowAllScores] = useState(true);
  const [touchedRadios, setTouchedRadios] = useState(false);

  //Scorefilter is enabled initially, this way the algolia component is rendered wherein we check if there is a current refinement already (taken from url). This check happens inside CustomRatingMenu. If no currentRefinement, scoreFilterEnabled will be set to false causing a fake StarRating component to be rendered (no algolia component because all results with score NULL won't display otherwise)
  const [scoreFilterEnabled, setScoreFilterEnabled] = useState(true);
  const [defaultStarValue, setDefaultStarValue] = useState(0);

  const handleReviewsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTouchedRadios(true);
    setReviewsValue((event.target as HTMLInputElement).value);
    if (
      event.target.value === t('app.com.pages.companySearch.filterSection.withReviews').toString()
    ) {
      //When filtering on reviews, all results will always have at least a score of 1. So we enable the algolia StarRating component automatically.
      setScoreFilterEnabled(true);
      setShowAllScores(false);
      if (defaultStarValue === 0) {
        setDefaultStarValue(2);
      }
    } else {
      setShowAllScores(true);
      setScoreFilterEnabled(false);
    }
  };

  const { data: sectors, isSuccess: sectorsSuccess } = useSectors();
  const handleScoreChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (parseInt(event.target.value) === -1) {
      setScoreFilterEnabled(false);
    } else {
      setDefaultStarValue(parseInt(event.target.value) * 2); //Times 2 because a score goes from 1 to 10 and we only show 5 rating options.
      setScoreFilterEnabled(true);
    }
  };

  const dontFilterReviews = () => {
    //Only called on first render if there is no currentRefinement on reviews (Review slider)
    setReviewsValue(t('app.com.pages.companySearch.filterSection.allCompanies').toString());
    if (touchedRadios) {
      setScoreFilterEnabled(false);
    }
    setShowAllScores(true);
  };

  const browserCountry = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const defaultCountry = browserCountry === 'Europe/Amsterdam' ? 'nl' : 'be';
  const [countries, setCountries] = useState([defaultCountry]);

  return (
    <>
      <FilterSection title={t('app.com.pages.companySearch.filterSection.sectors')}>
        {sectorsSuccess && (
          <>
            <SectorsMenu
              attribute="sectors_ids"
              showMore={showMore}
              limit={1000}
              showMoreLimit={1000}
              operator="or"
              mobile={isMobile}
              sectors={sectors}
            />
            {sectors.length > 10 && (
              <StyledTextButton variant="text" onClick={() => setShowMore(!showMore)}>
                {showMore
                  ? t('app.com.pages.companySearch.filterSection.showLess')
                  : t('app.com.pages.companySearch.filterSection.showMore')}
              </StyledTextButton>
            )}
          </>
        )}
      </FilterSection>
      <FilterSection title={t('app.com.pages.companySearch.filterSection.country')}>
        <CountryMenu
          defaultRefinement={[defaultCountry]}
          onSelect={setCountries}
          attribute="country"
          mobile={isMobile}
          operator="or"
        />
      </FilterSection>
      <ProvincesMenu attribute="province" mobile={isMobile} operator="or" countries={countries} />
      <FilterSection title={t('app.com.pages.companySearch.filterSection.score')}>
        {/*
          Because algolia ignores all results with NULL as a numerical search value, we render a 'fake' StarRating component until a minimum score is selected an thus filtering should take place.
          */}
        {scoreFilterEnabled ? (
          <CustomRatingMenu
            attribute={'score.data.score'}
            min={0}
            max={10}
            defaultRefinement={{ min: defaultStarValue }}
            defRefinement={{ min: defaultStarValue }}
            isMobile={isMobile}
            setRefinementEnabled={setScoreFilterEnabled}
            showAllScores={showAllScores}
          />
        ) : (
          <StarRating
            value={-1}
            onChange={handleScoreChange}
            isMobile={isMobile}
            showAllScores={showAllScores}
          />
        )}
      </FilterSection>
      <FilterSection title={t('app.com.pages.companySearch.filterSection.reviews')}>
        <RadioGroup
          aria-label="reviews"
          name="reviews"
          value={reviewsValue}
          onChange={handleReviewsChange}
        >
          <Radio
            value={t('app.com.pages.companySearch.filterSection.allCompanies').toString()}
            height={isMobile ? 3.5 : 3}
            label={t('app.com.pages.companySearch.filterSection.allCompanies')}
          />
          <Radio
            value={t('app.com.pages.companySearch.filterSection.withReviews').toString()}
            height={isMobile ? 3.5 : 3}
            label={t('app.com.pages.companySearch.filterSection.withReviews')}
          />
        </RadioGroup>
      </FilterSection>
      {reviewsValue === t('app.com.pages.companySearch.filterSection.withReviews') && (
        <FilterSection title={t('app.com.pages.companySearch.filterSection.numberOfReviews')}>
          <CustomRangeSlider
            attribute={'score.data.total'}
            onNoDefaultRefinement={dontFilterReviews}
            touchedRadios={touchedRadios}
          />
        </FilterSection>
      )}
    </>
  );
};

export default FilterComponent;
