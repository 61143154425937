import React, { FC, useEffect, useState } from 'react';
import { Configure, Index, InstantSearch } from 'react-instantsearch-dom';
import { Box, Grid, useMediaQuery, useTheme } from '@material-ui/core';
import { ALGOLIA_INDEX, searchClient } from '@homeproved/com/feature-search';
import {
  CustomHits,
  CustomSearchBox,
  CustomStats,
  useCoordinates,
} from '@homeproved/shared/feature-company-search';
import { useTranslation } from 'react-i18next';
import { Button, Icons, ShadowOverlay } from '@homeproved/shared/ui';
import {
  ContentWrapper,
  FacetTitle,
  Header,
  SearchTitle,
  SearchWrapper,
  Separator,
  Sidebar,
} from './components/Atoms';
import { useDisclosure } from 'react-use-disclosure';
import {
  createURL,
  urlToSearchState,
  useClickOutside,
  usePageScroll,
} from '@homeproved/shared/util';
import { CompanySearchSidebar } from './components/CompanySearchSidebar';
import styled from 'styled-components';
import FilterComponent from './components/FilterComponent';
import { useLocalizedRoutes } from '@homeproved/com/feature-localized-routes';
import { StyledPagination } from '@homeproved/shared/feature-algolia';
import { use100vh } from 'react-div-100vh';
import { useRouter } from 'next/router';

type CompanySearchPageProps = {
  searchQuery: string | undefined;
};

const PageWrapper = styled(({ height, ...restProps }) => <div {...restProps} />)`
  min-height: ${({ height }) => `${height}px`};
  overflow: hidden;
`;

export const CompanySearchPage: FC<CompanySearchPageProps> = ({ searchQuery }) => {
  const { t } = useTranslation();
  const { getPath } = useLocalizedRoutes();
  const { geoProps } = useCoordinates();
  const { setPageScrollEnabled } = usePageScroll();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.sm));
  const {
    isOpen: offCanvasOpen,
    close: onCloseOffCanvas,
    toggle: onToggleOffCanvas,
  } = useDisclosure(false);

  const [totalHits, setTotalHits] = useState(0);
  const [hitsPerPage, setHitsPerPage] = useState(10);
  const sidebarRef: React.RefObject<HTMLDivElement> = React.useRef();
  const toggleBtnRef: React.RefObject<HTMLDivElement> = React.useRef();

  useClickOutside(sidebarRef, onCloseOffCanvas, [toggleBtnRef]);

  const sidebarHeight = use100vh();

  const router = useRouter();

  const [searchState, setSearchState] = useState(urlToSearchState(createURL(router.query)));
  const [initialLoaded, setInitialLoaded] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      //to prevent that onSearchStateChange is called too many times initially. Otherwise saved search params will reset in the url on mobile for some reason.
      setInitialLoaded(true);
    }, 2000);
  }, []);

  const pushParams = (params) => {
    setPageScrollEnabled(false);

    router
      .replace(
        {
          pathname: getPath(router.pathname),
          query: createURL(params),
        },
        undefined,
        { shallow: true }
      )
      .then(() => {
        setTimeout(() => {
          //A timeout is needed. Otherwise you will scroll to the top of the page after every applied algolia-filtering, caused by useRouterScroll inside SharedShell. By disabling and enabling the scroll, we counter this effect.
          setPageScrollEnabled(true);
        }, 200);
      });
  };

  const onSearchStateChange = (updatedSearchState) => {
    if (initialLoaded) {
      setSearchState(updatedSearchState);
      pushParams(updatedSearchState);
    }
  };

  return (
    <InstantSearch
      searchClient={searchClient}
      indexName={ALGOLIA_INDEX.COMPANIES}
      searchState={searchState}
      onSearchStateChange={onSearchStateChange}
      createURL={createURL}
    >
      <CustomStats setTotalHits={setTotalHits} />
      <Index indexName={ALGOLIA_INDEX.COMPANIES} key={ALGOLIA_INDEX.COMPANIES}>
        <Configure {...geoProps} hitsPerPage={hitsPerPage} />
      </Index>
      {/* START Sidebar */}
      {isMobile && (
        <>
          <Sidebar
            innerRef={sidebarRef}
            mobile={isMobile}
            offCanvasOpen={offCanvasOpen}
            height={sidebarHeight}
          >
            <CompanySearchSidebar
              offCanvas={isMobile}
              onToggleOffCanvas={onToggleOffCanvas}
              toggleBtnRef={toggleBtnRef}
            >
              <FilterComponent isMobile={isMobile} />
            </CompanySearchSidebar>
          </Sidebar>
          {offCanvasOpen && <ShadowOverlay />}
        </>
      )}
      {/* END Sidebar */}
      <PageWrapper height={sidebarHeight}>
        <Header>
          <SearchTitle mobile={isMobile}>{t('app.com.pages.companySearch.title')}</SearchTitle>
          <SearchWrapper>
            <CustomSearchBox
              defaultRefinement={searchQuery ?? ''}
              placeholder={t('app.com.pages.companySearch.placeholder')}
            />
          </SearchWrapper>
        </Header>
        <ContentWrapper mobile={isMobile}>
          <Grid container spacing={6}>
            {!isMobile && (
              <Grid item xs={12} sm={4}>
                <FilterComponent isMobile={isMobile} />
              </Grid>
            )}
            <Grid item xs={12} sm={8}>
              {isMobile && (
                <Box maxWidth="50rem" margin="auto" mb={3}>
                  <Button
                    arrow="none"
                    variant="dark"
                    pill={false}
                    icon={Icons.FILTER}
                    fullWidth
                    onClick={onToggleOffCanvas}
                    innerRef={toggleBtnRef}
                  >
                    {t('app.com.pages.companySearch.filterSection.filterBtnText')}
                  </Button>
                </Box>
              )}
              <FacetTitle size="large" mobile={isMobile}>
                {`${t('app.com.pages.companySearch.mainSection.title')} `}
                <span style={{ fontWeight: 300 }}>({totalHits})</span>
              </FacetTitle>
              {isMobile && <Separator />}
              <CustomHits getPath={getPath} />
              {!isMobile && <StyledPagination />}
              {isMobile && totalHits > hitsPerPage && (
                <Box display="flex" flexDirection="column" alignItems="center" pt={5}>
                  <Button variant="light" onClick={() => setHitsPerPage(hitsPerPage + 10)}>
                    {t('app.com.pages.sectors.subSector.moreResults')}
                  </Button>
                </Box>
              )}
            </Grid>
          </Grid>
        </ContentWrapper>
      </PageWrapper>
    </InstantSearch>
  );
};
