import {
  RefinementListProvided,
  RefinementListExposed,
  connectRefinementList,
} from 'react-instantsearch-core';
import React from 'react';
import { Checkbox } from '@homeproved/shared/feature-forms';
import { useTranslation } from 'react-i18next';
import { FilteredList, FilteredListItem } from './Atoms';
import { FilterSection } from '@homeproved/com/feature-company-search';

const provinces = {
  be: [
    'antwerpen',
    'henegouwen',
    'limburg',
    'luik',
    'luxemburg',
    'namen',
    'oost-vlaanderen',
    'vlaams-brabant',
    'waals-brabant',
    'west-vlaanderen',
    'brussels-hoofdstedelijk-gewest',
  ],
  nl: [
    'Noord-Holland',
    'Zuid-Holland',
    'Zeeland',
    'Noord-Brabant',
    'Utrecht',
    'Flevoland',
    'Friesland',
    'Groningen',
    'Drenthe',
    'Overijssel',
    'Gelderland',
    'Limburg',
  ],
};

type Props = RefinementListProvided & { mobile: boolean; countries: string[] };

const ProvincesMenuComponent = ({ currentRefinement, refine, mobile, countries }: Props) => {
  const { t } = useTranslation();
  const refinedItems = currentRefinement;

  const handleChange = (isRefined, province) => {
    const newList = isRefined
      ? refinedItems.filter((refinedItem) => refinedItem !== province)
      : [...new Set([...refinedItems, province])];

    refine(newList);
  };

  return (
    <>
      {countries.map((country) => (
        <FilterSection title={t(`app.com.pages.companySearch.filterSection.${country}`)}>
          <FilteredList>
            {provinces[country].map((province) => {
              const isRefined = refinedItems.includes(province);
              return (
                <FilteredListItem key={province}>
                  <Checkbox
                    label={t(`provinces.${country}.${province}`)}
                    labelSize={1.5}
                    value={isRefined}
                    onChange={() => handleChange(isRefined, province)}
                    height={mobile ? 3.5 : 3}
                    labelWeight={isRefined && 700}
                    labelCapitalized
                  />
                </FilteredListItem>
              );
            })}
          </FilteredList>
        </FilterSection>
      ))}
    </>
  );
};

export const ProvincesMenu = connectRefinementList(ProvincesMenuComponent) as React.ComponentClass<
  RefinementListExposed & { mobile: boolean; countries: string[] }
>;
