import { StarRating } from '@homeproved/shared/feature-forms';
import React, { useEffect } from 'react';
import { connectRange } from 'react-instantsearch-dom';

const RatingMenu = ({
  currentRefinement,
  refine,
  isMobile,
  setRefinementEnabled,
  showAllScores,
  defRefinement,
}) => {
  /*
  As of Algolia's documentation, the history (url) is not updated on initial render with a default refinement.
  On mount, we check if a currentRefinement took place already (read from url). Otherwise, disable this component and render the fake one.
  */
  useEffect(() => {
    if (currentRefinement?.min == null || currentRefinement?.min === 0) {
      //disables this component
      setRefinementEnabled(false);
    } else {
      //On first render, the default refinement wont update algolia's searchState (and thus the url). We trigger a second refinement manually after 200ms. (Won't work without a delay).
      if (defRefinement?.min > 0) {
        setTimeout(() => {
          refine(defRefinement);
        }, 200);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleScoreChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (parseInt(event.target.value) === -1) {
      setRefinementEnabled(false);
    } else {
      refine({ min: parseInt((event.target as HTMLInputElement).value) * 2 });
    }
  };
  return (
    <StarRating
      value={currentRefinement.min / 2}
      onChange={handleScoreChange}
      isMobile={isMobile}
      showAllScores={showAllScores}
    />
  );
};

export const CustomRatingMenu = connectRange(RatingMenu);
