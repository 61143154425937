import { CustomSearchBox } from './CustomSearchBox';
import { Button, SectionTitle } from '@homeproved/shared/ui';
import { Box, Grid } from '@material-ui/core';
import { useRouter } from 'next/router';
import React, { FC, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ContentWrapper,
  SearchLabel,
  SearchSectionTitle,
  SearchTitle,
  SearchWrapper,
  StyledLink,
} from './Atoms';
import { CustomHits } from './CustomHits';
import { GetPathFunction } from '@homeproved/shared/feature-localized-routes';
import { Configure, Index, InstantSearch, InstantSearchProps } from 'react-instantsearch-dom';
import { useCurrentLanguage } from '@homeproved/shared/feature-i18n';
import { StyledPagination } from '@homeproved/shared/feature-algolia';
import { CustomStats } from './CustomStats';
import Link from 'next/link';
import { createURL, urlToSearchState, usePageScroll } from '@homeproved/shared/util';

type SearchSectionProps = {
  isMobile: boolean;
  getPath: GetPathFunction;
  sectorName: string;
  subSectorName?: string;
  searchClient?: InstantSearchProps['searchClient'];
  indexName?: InstantSearchProps['indexName'];
  resultsState?: InstantSearchProps['resultsState'];
  widgetsCollector?: InstantSearchProps['widgetsCollector'];
  page: string | undefined;
  query: string | undefined;
};

export const SearchSection: FC<SearchSectionProps> = ({
  isMobile,
  getPath,
  searchClient,
  indexName,
  resultsState,
  widgetsCollector,
  sectorName,
  subSectorName,
  page,
  query,
}) => {
  const currentLanguage = useCurrentLanguage();
  const [totalHits, setTotalHits] = useState(0);
  const router = useRouter();
  const { t } = useTranslation();
  const contentWrapperRef = useRef<HTMLDivElement>();
  const { setPageScrollEnabled } = usePageScroll();
  const [searchState, setSearchState] = useState(urlToSearchState(createURL({ page, query })));

  const pushParams = (params) => {
    setPageScrollEnabled(false);
    router
      .replace(
        {
          pathname: getPath(
            router.pathname,
            subSectorName
              ? {
                  sector: router.query.sector.toString(),
                  subsector: router.query.subsector.toString(),
                }
              : {
                  sector: router.query.sector.toString(),
                }
          ),
          query: params,
        },
        undefined,
        { shallow: true }
      )
      .then(() => {
        setTimeout(() => {
          //A timeout is needed. Otherwise you will scroll to the top of the page after every applied algolia-filtering, caused by useRouterScroll inside SharedShell. By disabling and enabling the scroll, we counter this effect.
          setPageScrollEnabled(true);
        }, 200);
      });
  };

  const onSearchStateChange = (updatedSearchState) => {
    setSearchState(updatedSearchState);
    pushParams(updatedSearchState);
  };

  const executeScroll = () => {
    if (contentWrapperRef && contentWrapperRef.current != null)
      contentWrapperRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
  };

  const handleOnClickAdvancedSearch = () => {
    router.push(getPath('/company-search'), undefined, { shallow: true }).then();
  };

  const [hitsPerPage, setHitsPerPage] = useState(10);

  return (
    <ContentWrapper mobile={isMobile} innerRef={contentWrapperRef}>
      {!isMobile && <SectionTitle label={t('app.com.pages.sectors.subSector.sectionTitle')} />}
      {isMobile && (
        <SearchSectionTitle variant="h2" mobile={isMobile}>
          {t('app.com.pages.sectors.subSector.sectionTitle')}
        </SearchSectionTitle>
      )}

      <Grid container justify="center">
        <Grid item xs={12} sm={8}>
          <InstantSearch
            searchClient={searchClient}
            indexName={indexName}
            resultsState={resultsState}
            widgetsCollector={widgetsCollector}
            searchState={searchState}
            onSearchStateChange={onSearchStateChange}
            createURL={createURL}
          >
            <CustomStats setTotalHits={setTotalHits} />
            <Index indexName={indexName}>
              <Configure
                facetFilters={
                  subSectorName
                    ? `${currentLanguage}.sectors.lvl1:${sectorName} > ${subSectorName}`
                    : `${currentLanguage}.sectors.lvl0:${sectorName}`
                }
                hitsPerPage={hitsPerPage}
              />
            </Index>

            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="flex-end"
              mb={isMobile ? 2 : 3}
            >
              <SearchWrapper mobile={isMobile}>
                <SearchLabel variant="body1" mobile={isMobile}>
                  {t('app.com.pages.sectors.subSector.searchLabel')}
                </SearchLabel>
                <CustomSearchBox placeholder=" " />
              </SearchWrapper>
              {!isMobile && (
                <Button variant="gradient" onClick={handleOnClickAdvancedSearch}>
                  {t('app.com.pages.sectors.subSector.advancedSearch')}
                </Button>
              )}
            </Box>
            {isMobile && (
              <Box mb={4} display="flex" flexDirection="column" alignItems="center">
                <Link href={getPath('/company-search')} passHref>
                  <StyledLink mobile={isMobile}>
                    {t('app.com.pages.sectors.subSector.advancedSearch')}
                  </StyledLink>
                </Link>
              </Box>
            )}
            {!isMobile && (
              <SearchTitle variant="h3">
                {`${t('app.com.pages.sectors.subSector.searchResults')} `}
                <span style={{ fontWeight: 300 }}>({totalHits})</span>
              </SearchTitle>
            )}
            <Box>
              <CustomHits getPath={getPath} />
              {!isMobile && <StyledPagination onExecuteScroll={executeScroll} />}
              {isMobile && totalHits > hitsPerPage && (
                <Box display="flex" flexDirection="column" alignItems="center" pt={5} pb={5}>
                  <Button variant="light" onClick={() => setHitsPerPage(hitsPerPage + 10)}>
                    {t('app.com.pages.sectors.subSector.moreResults')}
                  </Button>
                </Box>
              )}
            </Box>
          </InstantSearch>
        </Grid>
      </Grid>
    </ContentWrapper>
  );
};
