import React, { FC } from 'react';
import styled from 'styled-components';
import { IconButton, Icons, Initials, Stars, SvgIcon } from '@homeproved/shared/ui';
import { CompanyData } from '@homeproved/shared/data-access';
import { Box, useMediaQuery, useTheme } from '@material-ui/core';
import { useCurrentLanguage } from '@homeproved/shared/feature-i18n';
import { useTranslation } from 'react-i18next';
import { Company, LocationTag, Logo, RatingWrapper, StarsContainer, Title, Tags } from './Atoms';
import { useRouter } from 'next/router';
import { GetPathFunction } from '@homeproved/shared/feature-localized-routes';
import { ExtraCompanyData } from './CustomHits';
import { getInitials } from '@homeproved/shared/util';

const ReviewsLabel = styled.span`
  font-size: 1.2rem;
  position: relative;

  b {
    margin: 0 5px;
  }
`;

const WorkTag = styled.img`
  width: 2.5rem;
  height: 2.5rem;
  fill: #aaa;
`;

const ScoreLabelWrapper = styled(({ mobile, ...restProps }) => <div {...restProps} />)`
  font-family: ${({ theme }) => theme.config.fonts.Cabrito};
  color: #333333;
  margin-top: -1rem;
`;

const Wrapper = styled(({ mobile, ...restProps }) => <div {...restProps} />)`
  position: relative;
  margin: 2rem 0;
  overflow: hidden;
  /* Little hack for safari iphone. Otherwise border-radius won't be taken into account for the overflowing validation flag */
  transform: translateZ(0);
  /* end hack */
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.values.sm + 'px'}) {
    margin: 2rem 0;
  }

  &:nth-of-type(1) {
    margin-top: 1rem;
  }

  padding: 1rem;
  background: #fff;
  transition: all 0.3s ease;
  cursor: pointer;
  border-bottom: 0.2rem solid ${({ theme }) => theme.palette.grey['border']};

  .MuiIconButton-root {
    padding: 8px;
  }

  ${({ mobile }) =>
    `
    padding: 0;
    display: flex;
    ${StarsContainer} {
      min-width: ${mobile ? '100%' : '400px'};
      display:flex;
      flex-wrap:wrap;
      align-items:center;
    }
    ${Company} {
      ${Title} {
        margin-bottom: 1rem;
      }
    }
    ${RatingWrapper} {
      border-bottom: none;
      padding: 0;
      margin: 0;
    }
    ${Logo} {
      border-radius: 50%;
      padding: 10px;
      width: 6rem;
      height: 6rem;
      position: relative;
    }
    ${Initials} {
      width: 6rem;
      height: 75px;
    }
  `}
`;

export type CompanySearchCardProps = {
  company: CompanyData & ExtraCompanyData;
  getPath: GetPathFunction;
  review?: boolean;
};

export const CompanySearchCard: FC<CompanySearchCardProps> = ({ company, getPath, review }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.xs));
  const language = useCurrentLanguage();
  const router = useRouter();
  let tags = [];
  if (company[`${language}.sectors.lvl1`] != null) {
    if (Array.isArray(company[`${language}.sectors.lvl1`])) {
      tags = company[`${language}.sectors.lvl1`].map((unFilteredTag) =>
        unFilteredTag.split('>')[1].replace(/^\s+/g, '')
      );
    } else {
      tags.push(company[`${language}.sectors.lvl1`].split('>')[1].replace(/^\s+/g, ''));
    }
  }
  const navigateToCompanyPage = () => {
    router
      .push(
        getPath('/company/:slug/reviews', {
          slug: company.slug,
        })
      )
      .then();
  };

  const navigateToReview = () => {
    router
      .push(
        getPath('/write-review/:slug', {
          slug: company.slug,
        })
      )
      .then();
  };
  const logo = company.media?.find((image) => !!image.data?.['conversions']?.['small'])?.data?.[
    'conversions'
  ]?.['small'];
  const hasLogo = !!logo;

  return (
    <Wrapper mobile={isMobile} onClick={review ? navigateToReview : navigateToCompanyPage}>
      {!isMobile && (
        <Box>
          {hasLogo ? (
            <Logo image={logo} />
          ) : (
            <Initials fontSize={5} background>
              {getInitials(company?.name)}
            </Initials>
          )}
        </Box>
      )}
      <Company>
        <Box
          display="flex"
          width="100%"
          justifyContent="space-between"
          flexWrap="wrap"
          mb={1}
          flexDirection={isMobile ? 'column' : 'row'}
        >
          <Box>
            <Title>
              {company.name}
              {!!company.claimed_at && (
                <Box display="inline-block" ml={1}>
                  <IconButton
                    icon={Icons.CHECKMARK_OUTLINE}
                    size={0.8}
                    width={1}
                    tooltip={t('app.com.pages.companySearch.mainSection.tooltipClaimed')}
                    variant="light"
                    iconColor={theme.palette.green.main}
                  />
                </Box>
              )}
            </Title>
          </Box>

          <Box display="flex" width={210} maxWidth={210}>
            {company?.score?.data?.total > 0 ? (
              <ScoreLabelWrapper>
                <Stars count={company?.score?.data?.score} size={2.5} showRating />
                <ReviewsLabel>
                  {company?.score?.data?.total > 0 ? (
                    <>
                      <strong>{company?.score?.data?.total}</strong>&nbsp;
                      <strong>
                        {`${
                          company?.score?.data?.total > 1
                            ? t('app.com.pages.company.companyShell.xReviews')
                            : t('app.com.pages.company.companyShell.xReview')
                        }`}
                      </strong>
                    </>
                  ) : (
                    <i>{t('app.noReviews')}</i>
                  )}
                </ReviewsLabel>
              </ScoreLabelWrapper>
            ) : null}
            {company?.score?.data?.total === 0 && (
              <ScoreLabelWrapper>
                <ReviewsLabel>
                  <i>{t('app.noReviews')}</i>
                </ReviewsLabel>
              </ScoreLabelWrapper>
            )}
          </Box>
        </Box>
        <Box display="flex" flexWrap="wrap" mb={1}>
          <Box display="flex" alignItems="center">
            <SvgIcon icon={Icons.LOCATION_OUTLINE} size={2} />
            <LocationTag mobile={isMobile}>{company.city}</LocationTag>
          </Box>
          {tags.length > 0 && (
            <Box ml={isMobile ? 0 : 1} display="flex" alignItems="center">
              <WorkTag src="/work.svg" alt="tags" />
              <Tags mobile={isMobile}>
                {tags.map((tag, index) => (
                  <span key={index}>
                    {tag}&nbsp;{index + 1 !== tags.length && '-'}&nbsp;
                  </span>
                ))}
              </Tags>
            </Box>
          )}
        </Box>
      </Company>
      {isMobile && (
        <Box>
          {hasLogo ? (
            <Logo image={logo} />
          ) : (
            <Initials fontSize={5} background>
              {getInitials(company?.name)}
            </Initials>
          )}
        </Box>
      )}
    </Wrapper>
  );
};
