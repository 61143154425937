import { SectorsApiFactory, useApiFactory, useQueryFetch } from '@homeproved/shared/data-access';
import QUERY_KEYS from './api/queryKeys';
import { useCurrentLanguage } from '@homeproved/shared/feature-i18n';

export function useSectors() {
  const currentLanguage = useCurrentLanguage();
  const sectorsApi = useApiFactory(SectorsApiFactory);
  const {
    query: { data, isLoading, isSuccess, error },
  } = useQueryFetch([QUERY_KEYS.SECTORS, currentLanguage], sectorsApi.apiSectorGet);
  return { data: data?.data, isLoading, isSuccess, error };
}

export function useTeasers() {
  const currentLanguage = useCurrentLanguage();
  const sectorsApi = useApiFactory(SectorsApiFactory);
  const {
    query: { data, isLoading, isSuccess, error },
  } = useQueryFetch(['sectorTeasers', currentLanguage], sectorsApi.apiSectorTeasersGet);
  return { data: data?.data, isLoading, isSuccess, error };
}
