import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SectorData, SectorDescendant } from '@homeproved/shared/data-access';
import { Button, Icons, ResponsiveImage, SvgIcon } from '@homeproved/shared/ui';
import { GetPathFunction } from '@homeproved/shared/feature-localized-routes';
import {
  DotsOuter,
  DotsOuterPlaceholder,
  ExpandableClose,
  ExpandableWrapper,
  Icon,
  SectorCard,
  SectorDescendantsWrapper,
  SectorNotFound,
  SectorTitle,
  SubSectorEntry,
  GridItem,
} from './Atoms';
import { Box, Grid } from '@material-ui/core';
import { useRouter } from 'next/router';
import { useCurrentLanguage } from '@homeproved/shared/feature-i18n';

type SectorListProps = {
  sectors: SectorData[];
  getPath: GetPathFunction;
  isMobile: boolean;
  isTablet: boolean;
};

export const SectorList: FC<SectorListProps> = ({ sectors, getPath, isMobile, isTablet }) => {
  const { t } = useTranslation();
  const [expandedSector, setExpandedSector] = useState<number | undefined>();
  const router = useRouter();
  const [navigating, setNavigating] = useState<number | false>(false);

  useEffect(() => {
    if (navigating) {
      setExpandedSector(navigating);
    }
  }, [navigating]);

  const currentLanguage = useCurrentLanguage();

  return (
    <Box marginLeft={isMobile ? '-1.2rem' : 0} marginRight={isMobile ? '-1.2rem' : 0}>
      <Grid container spacing={isMobile ? 2 : 3}>
        {sectors.map((sector, index) => (
          <GridItem item xs={6} sm={3} mobile={isMobile} key={'sector-' + sector.id}>
            <SectorCard
              key={index}
              mobile={isMobile}
              tablet={isTablet}
              onClick={() => setExpandedSector((s) => (!s || s !== sector.id) && sector.id)}
            >
              <ResponsiveImage
                src={sector.cover?.data?.conversions?.['small'] || sector.cover?.data?.original}
                alt={sector.name}
                ratio={1}
                borderRadius={0.5}
              />
              <SectorTitle mobile={isMobile}>
                {sector.icon && !isMobile && (
                  <Icon
                    icon={
                      Icons[
                        sector.icon === 'helmet' || sector.icon == null
                          ? 'HELMET_OUTLINE'
                          : sector.icon.toUpperCase()
                      ]
                    }
                  />
                )}
                <b>{sector.name}</b>
              </SectorTitle>
              {sector.descendants.length !== 0 ? (
                <>
                  <div>
                    <ExpandableWrapper visible={expandedSector === sector.id}>
                      <SectorDescendantsWrapper>
                        <SubSectorEntry
                          variant="body1"
                          onClick={() => {
                            setNavigating(sector.id);
                            router
                              .push(
                                getPath('/sectors/:sector', {
                                  sector: sector.slug.data[currentLanguage],
                                })
                              )
                              .then();
                          }}
                          isSector
                          mobile={isMobile}
                        >
                          {t('app.com.pages.sectors.ctaAll')}
                        </SubSectorEntry>
                        {sector.descendants.map((sectorDescendant: SectorDescendant) => (
                          <SubSectorEntry
                            variant="body1"
                            key={sectorDescendant.data.id}
                            onClick={() => {
                              setNavigating(sector.id);
                              router
                                .push(
                                  getPath('/sectors/:sector/:subsector', {
                                    sector: sector.slug.data[currentLanguage],
                                    subsector: sectorDescendant.data.slug.data[
                                      currentLanguage
                                    ].toString(),
                                  })
                                )
                                .then();
                            }}
                            mobile={isMobile}
                          >
                            {sectorDescendant.data.name}
                          </SubSectorEntry>
                        ))}
                      </SectorDescendantsWrapper>
                      <ExpandableClose>
                        <SvgIcon icon={Icons.ANGLE_UP} />
                      </ExpandableClose>
                    </ExpandableWrapper>
                  </div>
                  <DotsOuter>
                    <SvgIcon icon={Icons.KEBAB} size={1.5} />
                  </DotsOuter>
                </>
              ) : (
                <DotsOuterPlaceholder />
              )}
            </SectorCard>
          </GridItem>
        ))}
        <GridItem item xs={12} sm={3} mobile={isMobile}>
          <SectorNotFound>
            <b>{t('app.com.pages.sectors.sector_not_found')}</b>
            <Button variant="light" href={getPath('/company-search')}>
              {t('app.com.pages.sectors.find_button')}
            </Button>
          </SectorNotFound>
        </GridItem>
      </Grid>
    </Box>
  );
};
